import {
  CurrentUserContextType,
  QBox,
  QButton,
  QEmptyState,
  QSelect,
  QSpinner,
  QStack,
  QText,
  useCurrentUser,
  useToastProvider,
} from '@qualio/ui-components';
import { useContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { changeControlApi } from '../../../../api/changeControl';
import { documentApi } from '../../../../api/document';
import { useSortedList } from '../../../../hooks/SortedList';
import {
  COMMENT_HISTORY_FETCH_DATA_ERROR,
  logCustomError,
} from '../../../../messages/LogErrorMessages';
import { ChangeControl } from '../../../../types/DocumentCreate';

import { DocumentOverviewContext } from '../../DocumentOverview/Context';
import { CommentThreadList } from './CommentThreadList';
import { parseHistoricalCommentThreadsForDisplay } from './HistoricalComments/HistoricalCommentThread';
import {
  HistoricalCommentThread,
  HistoricalCommentThreadList,
} from './HistoricalComments/HistoricalCommentThreadList';

interface CommentHistoryTabProps {
  companyId: number;
  documentId: string;
  isOpen: boolean;
}

type CommmentContextSelectOptions = {
  label: CommmentContextOptions;
  value: CommentContext;
};

export enum CommmentContextOptions {
  DOCUMENT = 'Document',
  CHANGE_CONTROL = 'Change control',
}

export type CommentContext = 'document' | 'change-control';

const FROALA_CC_RETIRE_DATE = 1728691200;

interface CommentsListDescendingProps {
  commentThreads: CommentsRepository.CommentThreadFetched[] | undefined;
  selectedCommentContext: CommentContext;
  historicalCommentThreads: HistoricalCommentThread[] | undefined;
  currentUser: CurrentUserContextType;
}

const CommentsListDescending = ({
  commentThreads,
  historicalCommentThreads,
  selectedCommentContext,
  currentUser,
}: CommentsListDescendingProps): JSX.Element => {
  return (
    <>
      {commentThreads && commentThreads.length > 0 && (
        <CommentThreadList threads={commentThreads} currentUser={currentUser} />
      )}

      {selectedCommentContext === 'change-control' &&
        historicalCommentThreads &&
        historicalCommentThreads.length > 0 && (
          <HistoricalCommentThreadList
            threads={historicalCommentThreads}
            currentUser={currentUser}
          />
        )}
    </>
  );
};

const CommentsListAscending = ({
  commentThreads,
  historicalCommentThreads,
  selectedCommentContext,
  currentUser,
}: CommentsListDescendingProps): JSX.Element => {
  return (
    <>
      {selectedCommentContext === 'change-control' &&
        historicalCommentThreads &&
        historicalCommentThreads.length > 0 && (
          <HistoricalCommentThreadList
            threads={historicalCommentThreads}
            currentUser={currentUser}
          />
        )}

      {commentThreads && commentThreads.length > 0 && (
        <CommentThreadList threads={commentThreads} currentUser={currentUser} />
      )}
    </>
  );
};

export const CommentHistoryTab = ({
  companyId,
  documentId,
  isOpen,
}: CommentHistoryTabProps) => {
  const { showToast } = useToastProvider();
  const { changeControl } = useContext(DocumentOverviewContext);
  const currentUser = useCurrentUser();
  const [selectedCommentContext, setSelectedCommentContext] =
    useState<CommentContext>('document');
  const commentContextOptions: CommmentContextSelectOptions[] = [
    {
      label: CommmentContextOptions.DOCUMENT,
      value: 'document',
    },
    {
      label: CommmentContextOptions.CHANGE_CONTROL,
      value: 'change-control',
    },
  ];

  const onCommentHistoryContextChange = (
    selectedCommentContext: CommmentContextSelectOptions | null,
  ) => {
    if (selectedCommentContext) {
      setSelectedCommentContext(selectedCommentContext.value);
    }
  };

  const loadComments = async (companyId: number, documentId: string) => {
    return await documentApi.fetchCommentThreads(
      companyId,
      documentId,
      selectedCommentContext,
    );
  };

  const { isFetching: isFetchingComments, data: commentThreads } = useQuery({
    queryKey: ['commentHistory', companyId, documentId, selectedCommentContext],
    queryFn: () => loadComments(companyId, documentId),
    refetchOnWindowFocus: false,
    enabled: isOpen,
    onError: (error) => {
      showToast({
        id: 'error-comment-history',
        title: 'Error',
        description: 'Could not retrieve comment history',
        status: 'error',
      });
      logCustomError(COMMENT_HISTORY_FETCH_DATA_ERROR, { error });
    },
  });

  const loadHistoricalComments = async (
    changeControl: ChangeControl | undefined,
  ): Promise<HistoricalCommentThread[]> => {
    return await changeControlApi
      .fetchHistoricalComments(changeControl!.id)
      .then((fetchedHistoricalComments) =>
        parseHistoricalCommentThreadsForDisplay(fetchedHistoricalComments),
      );
  };

  const {
    isFetching: isFetchingHistoricalComments,
    data: historicalCommentThreads = [],
  } = useQuery({
    queryKey: ['historicalComments', companyId, changeControl],
    queryFn: () => loadHistoricalComments(changeControl),
    refetchOnWindowFocus: false,
    enabled: Boolean(
      isOpen &&
        changeControl &&
        changeControl.created_time < FROALA_CC_RETIRE_DATE &&
        changeControl.status === 'open',
    ),
    onError: (error) => {
      showToast({
        id: 'error-comment-history',
        title: 'Error',
        description: 'Could not retrieve comment history',
        status: 'error',
      });
      logCustomError(COMMENT_HISTORY_FETCH_DATA_ERROR, { error });
    },
  });

  const {
    sortedList: sortedHistoricalCommentThreads,
    sortDirection: commentsSortDirection,
    toggleSortDirection: toggleHistoricalCommentsSortDirection,
  } = useSortedList(historicalCommentThreads);
  const {
    sortedList: sortedCommentThreads,
    toggleSortDirection: toggleCommentThreadsSortDirection,
  } = useSortedList(commentThreads);

  const toggleBoth = () => {
    toggleHistoricalCommentsSortDirection();
    toggleCommentThreadsSortDirection();
  };

  const showSortButton = useMemo(() => {
    return (
      (historicalCommentThreads && historicalCommentThreads.length > 0) ||
      (commentThreads && commentThreads.length > 0)
    );
  }, [historicalCommentThreads, commentThreads]);

  if (isFetchingComments || isFetchingHistoricalComments) {
    return (
      <QStack height="100%" minHeight="60vh" justify="center" align="center">
        <QSpinner />
        <QText data-cy="loading-comments-history">Loading comments...</QText>
      </QStack>
    );
  }

  return (
    <QStack fontSize="sm" pt="4" spacing="4">
      {!isFetchingComments && (
        <QStack direction="row" justifyContent="space-between">
          <QBox width={160}>
            <QSelect
              size="sm"
              options={commentContextOptions}
              onChange={onCommentHistoryContextChange}
              value={selectedCommentContext}
              aria-label="Comment context"
              data-cy="comment-context-select"
            ></QSelect>
          </QBox>

          <>
            {showSortButton ? (
              <>
                {commentsSortDirection === 'ascending' ? (
                  <QButton
                    rightIcon="ArrowUp"
                    onClick={toggleBoth}
                    variant="ghost"
                  >
                    Oldest first
                  </QButton>
                ) : (
                  <QButton
                    rightIcon="ArrowDown"
                    onClick={toggleBoth}
                    variant="ghost"
                  >
                    Newest first
                  </QButton>
                )}
              </>
            ) : null}
          </>
        </QStack>
      )}

      <QStack fontSize="sm" spacing="4">
        {commentThreads?.length === 0 &&
        historicalCommentThreads?.length === 0 ? (
          <QEmptyState
            data-cy="comments-history-empty-placeholder"
            title="No comments added to the current version."
            subtitle="Add a comment to begin the discussion!"
          />
        ) : commentsSortDirection === 'descending' ? (
          <CommentsListDescending
            commentThreads={sortedCommentThreads}
            historicalCommentThreads={sortedHistoricalCommentThreads}
            selectedCommentContext={selectedCommentContext}
            currentUser={currentUser}
          />
        ) : (
          <CommentsListAscending
            commentThreads={sortedCommentThreads}
            historicalCommentThreads={sortedHistoricalCommentThreads}
            selectedCommentContext={selectedCommentContext}
            currentUser={currentUser}
          />
        )}
      </QStack>
    </QStack>
  );
};
