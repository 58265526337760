import { Parser } from 'html-to-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { forwardRef, useEffect, useMemo } from 'react';

import { QualioDocument } from '../../../api/model/document';
import { setupAttachmentPreviewListener } from '../../../config/handlers/index';
import { getDocumentEditorGlobalWindowObject } from '../../../util/AppUtils';
import { DocumentPresentationUtils } from '../../../util/DocumentPresentationUtils';
import { DocumentLayout } from '../DocumentLayout/DocumentLayout';
import styles from '../DocumentOverview/DocumentEditor/DocumentEditor.module.css';
import { TableOfContents } from '../TableOfContents';

export type DocumentHTMLViewerProps = {
  viewDocument: QualioDocument;
  showSidebar: boolean;
  showTOC: boolean;
  setLoaded: boolean;
  handleAttachmentClick?: (attachmentId: string) => void;
};

export const DocumentHTMLViewer = forwardRef<
  HTMLDivElement,
  DocumentHTMLViewerProps
>(
  (
    { viewDocument, showSidebar, showTOC, setLoaded, handleAttachmentClick },
    ref,
  ) => {
    const { santitizeHtmlView, newDocumentLayout } = useFlags();

    const htmlContent = useMemo(() => {
      const htmlToReactParser = new Parser();
      const processedDocumentContent =
        DocumentPresentationUtils.determineHtmlDocumentContentFromDocument(
          viewDocument,
          false,
          false,
          false,
          santitizeHtmlView,
        );
      return htmlToReactParser.parse(processedDocumentContent);
    }, [viewDocument, santitizeHtmlView]);

    useEffect(() => {
      const contentElement = document.querySelector(
        '#documentHTMLViewerContent',
      );
      if (contentElement && handleAttachmentClick) {
        setupAttachmentPreviewListener(contentElement, handleAttachmentClick);
      }
    }, [handleAttachmentClick]);

    // Hook for MTFE to know that the editor is loaded
    // to enable the edit and export button
    if (setLoaded) {
      getDocumentEditorGlobalWindowObject().loaded = true;
    }

    if (newDocumentLayout) {
      return (
        <DocumentLayout
          ref={ref}
          sections={viewDocument.sections}
          sectionTitlesVisible={viewDocument.text_sections_titles_visible}
          editorName="documentHTMLViewerContent"
          contentId="documentHTMLViewerContent"
          sidebarId="commentsSidebarPlaceholder"
          dataCy={{
            content: 'documentHTMLViewerContent',
          }}
        >
          {htmlContent}
        </DocumentLayout>
      );
    }

    return (
      <div
        ref={ref}
        className={`${styles['document-editor__document__content-html-view-container']} ${styles['document-editor__document__content-container__show']}`}
      >
        {showTOC && (
          <TableOfContents
            sections={viewDocument.sections}
            sectionTitlesVisible={viewDocument.text_sections_titles_visible}
            editorName="documentHTMLViewerContent"
          />
        )}
        <div
          id="documentHTMLViewerContent"
          data-cy="documentHTMLViewerContent"
          className={`${styles['document-editor__document__section-content']} document non-editable-mode`}
        >
          {htmlContent}
        </div>
        {showSidebar && (
          <div
            id="commentsSidebarPlaceholder"
            className={`${styles['document-editor__document__sidebar']}`}
          ></div>
        )}
      </div>
    );
  },
);
