export const getAttachmentPreviewUrl = (attachmentId: string) => {
  const apiUrl = process.env.REACT_APP_MEDTECH_API_URL?.replace(
    /(^\w+:|^)/,
    '',
  );
  return `${apiUrl}/preview/${attachmentId}`;
};

export const getAttachmentUrl = (attachmentId: string, download = false) => {
  const apiUrl = process.env.REACT_APP_MEDTECH_API_URL?.replace(
    /(^\w+:|^)/,
    '',
  );
  // Note: MTBE generates redirect urls and defaults the download param to false if not provided
  return `${apiUrl}/attachments/${attachmentId}?download=${download}`;
};
