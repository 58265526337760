import { QSpinner } from '@qualio/ui-components';
import { Tag } from '../../../../api/model/tag';
import { TagLabel } from '../../TagLabel/TagLabel';

export const ActiveTagFilter = ({
  value,
  tagsMap,
}: {
  tagsMap: Record<number, Tag>;
  value: unknown;
}) => {
  const tagId = value as number;
  if (tagsMap[tagId]) {
    return <TagLabel tag={tagsMap[tagId]} />;
  }
  return <QSpinner size="sm" />;
};
