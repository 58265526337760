import axios, { AxiosRequestConfig } from 'axios';
import { configureAxiosMocks } from './mocks';

configureAxiosMocks();

const MEDTECH_API_URL = process.env.REACT_APP_MEDTECH_API_URL;
export const MEDTECH_UPLOAD_URL = `${MEDTECH_API_URL}/attachments`;

const defaultAxiosRequestConfig: AxiosRequestConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
};

export const medtechApiClient = axios.create({
  ...defaultAxiosRequestConfig,
  baseURL: MEDTECH_API_URL,
});

export const templateApiClient = axios.create({
  baseURL: process.env.REACT_APP_TEMPLATE_API_URL,
  withCredentials: true,
});

export const tokenApiClient = axios.create({
  ...defaultAxiosRequestConfig,
  baseURL: process.env.REACT_APP_CKE_TOKEN_URL,
});

export const pdfApiClient = axios.create({
  ...defaultAxiosRequestConfig,
  baseURL: process.env.REACT_APP_CKE_PDF_SERVICE_URL,
});

export const migrationManagerApiClient = axios.create({
  ...defaultAxiosRequestConfig,
  baseURL: process.env.REACT_APP_MIGRATION_MANAGER_API_URL,
});

export const integrationsApiClient = axios.create({
  ...defaultAxiosRequestConfig,
  baseURL: process.env.REACT_APP_INTEGRATION_API_URL,
});
