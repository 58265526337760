import {
  QBox,
  QFlex,
  QIconButton,
  QSpacer,
  QTooltip,
} from '@qualio/ui-components';
import { forwardRef, ReactNode, useRef } from 'react';
import { EditorName } from '../../../config/handlers';
import { BackToTop } from '../BackToTop';
import { EditorStatus } from '../DocumentOverview/Context';
import { TableOfContents } from '../TableOfContents';
import styles from './DocumentLayout.module.css';

interface DocumentLayoutProps {
  isEditMode?: boolean;
  sections?: any[];
  sectionTitlesVisible?: boolean;
  commentsPermission?: number;
  editorStatus?: EditorStatus;
  editorName?: EditorName;
  children: ReactNode;
  contentId?: string;
  commentControlId?: string;
  sidebarId?: string;
  dataCy?: {
    content?: string;
    commentControl?: string;
    commentButton?: string;
    commentSidebar?: string;
  };
  showComments?: boolean;
  showTOC?: boolean;
}

export const DocumentLayout = forwardRef<HTMLDivElement, DocumentLayoutProps>(
  (
    {
      isEditMode = false,
      sections = [],
      sectionTitlesVisible = true,
      commentsPermission = 0,
      editorName,
      editorStatus,
      children,
      contentId,
      commentControlId,
      sidebarId,
      dataCy,
      showComments = true,
      showTOC = true,
    }: DocumentLayoutProps,
    ref,
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const commentsEnabled = showComments && commentsPermission > 1;

    return (
      <QFlex
        flexShrink={1}
        flexGrow={1}
        justifyContent="space-between"
        bg="gray.50"
        border="1px"
        borderTop={isEditMode ? 'none' : '1px'}
        borderColor="gray.200"
        borderRadius={isEditMode ? '0 0 4px 4px' : '4px'}
        ref={ref}
        className={`${styles['document-content-container']}`}
        style={{
          minWidth: commentsEnabled
            ? 'calc(var(--A4PageWidth) + 144px)'
            : showTOC
            ? 'calc(var(--A4PageWidth) + 64px)'
            : 'calc(var(--A4PageWidth) + 32px)',
        }}
        overflowY="hidden"
        overflowX="hidden"
      >
        <QFlex
          direction="column"
          flexShrink={1}
          flexGrow={1}
          ref={containerRef}
          className={`${styles['document-toc-container']}`}
          style={{
            minWidth: showTOC ? '48px' : '0px',
          }}
        >
          {showTOC && (
            <TableOfContents
              editorName={editorName}
              sections={sections}
              sectionTitlesVisible={sectionTitlesVisible}
            />
          )}
          <QSpacer height="100%" />
          <BackToTop parentRef={containerRef} />
        </QFlex>
        <QFlex position="relative" height="fit-content">
          <QBox
            my={4}
            ml={0}
            border="1px"
            borderColor="gray.200"
            boxShadow="md"
            id={contentId}
            data-cy={dataCy?.content}
            className={`${styles['document-section-content']} document ${
              !isEditMode ? 'non-editable-mode' : ''
            }`}
          >
            {children}
          </QBox>
          {commentsEnabled && (
            <QBox
              px={2}
              py={4}
              id={commentControlId}
              data-cy={dataCy?.commentControl}
              className={`${styles['document-comment-control']}`}
            >
              <QTooltip
                label={'Select content to add a comment'}
                placement={'top'}
              >
                <p>
                  <QIconButton
                    iconName={'MessageSquare'}
                    aria-label="icon-button"
                    size="sm"
                    data-cy={dataCy?.commentButton}
                  />
                </p>
              </QTooltip>
            </QBox>
          )}
        </QFlex>

        <QBox
          className={`${styles['document-sidebar']}`}
          style={{
            minWidth: commentsEnabled ? '48px' : '0px',
          }}
        >
          {showComments && (
            <QBox
              flexShrink={1}
              id={sidebarId}
              data-cy={dataCy?.commentSidebar}
              className={`${
                editorStatus === EditorStatus.CONNECTING ? 'ck-hidden' : ''
              } transition_on_click`}
            />
          )}
        </QBox>
      </QFlex>
    );
  },
);
