import { CurrentUser } from '@qualio/ui-components';

import { DocumentStatus } from '../../../api/model/document';
import { Template } from '../../../api/templateApi';

import { sortByLabel } from '../../../util/SelectionOptionUtils';
import { ALL_OPEN_STATUSES, WorkspaceTabProps, WorkspaceTabs } from './types';

export const getDefaultStatus = (
  tab: WorkspaceTabs | null,
  currentUser: CurrentUser,
) => {
  return !currentUser.permissions.can_view_all_docs &&
    tab === WorkspaceTabs.ALL_DOCUMENTS
    ? DocumentStatus.Effective
    : ALL_OPEN_STATUSES;
};

export const buildSelectedStatus = (
  statusParam: string | null,
  currentUser: CurrentUser,
  currentTab: WorkspaceTabs | null,
  currentTabConfig: WorkspaceTabProps,
) => {
  if (statusParam === null) {
    const defaultStatus = getDefaultStatus(currentTab, currentUser);
    return currentTabConfig.statusFiltersMap[defaultStatus].statusFilters;
  } else {
    return currentTabConfig.statusFiltersMap[statusParam].statusFilters;
  }
};

export const getTemplatesOptions = (templates: Template[], value = 'id') => {
  return templates
    .map((template: Template) => ({
      label: template.name + ' (' + template.prefix + ')',
      value: template[value as keyof Template]?.toString(),
      properties: {
        effective_on_approval: template.document_effective_on_approval,
        training_required: template.training_required,
        review_period: template.document_review_period,
        training_available: template.training_available,
        file_download_user_groups: template.file_download_user_groups
          ? template.file_download_user_groups
          : [],
        template_id: template.id,
        id: template.id,
      },
    }))
    .sort(sortByLabel);
};
