import { QSpinner } from '@qualio/ui-components';
import { Template } from '../../../../api/templateApi';

export const ActiveTemplateFilter = ({
  value,
  templatesMap,
}: {
  value: unknown;
  templatesMap: Record<string, Template>;
}) => {
  const templateCode = value as string;
  const template = templatesMap[templateCode];
  if (template) {
    return <>{`${template.name} (${template.prefix})`}</>;
  }
  return <QSpinner size="sm" />;
};
