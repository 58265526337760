import { QTagProps } from '@qualio/ui-components';
import { FilterDefinition } from '@qualio/ui-components/lib/QOrganisms/Table/Filtering';
import { DocumentStatus } from '../api/model/document';

type DocumentStatusConfig = {
  label: string;
  color: QTagProps['variantColor'];
};

export type WorkspaceFilteringDefinition = {
  filteringTag: FilterDefinition;
  subfilter: FilterDefinition;
};

export const DocumentStatusIdToStatusConfigMap: {
  [statusId in DocumentStatus]: DocumentStatusConfig;
} = {
  [DocumentStatus.Approved]: {
    label: 'Approved',
    color: 'purple',
  },
  [DocumentStatus.Approval_Declined]: {
    label: 'Approval declined',
    color: 'red',
  },
  [DocumentStatus.Deleted]: {
    label: 'Deleted',
    color: 'red',
  },
  [DocumentStatus.Draft]: {
    label: 'Draft',
    color: 'gray',
  },
  [DocumentStatus.Effective]: {
    label: 'Effective',
    color: 'green',
  },
  [DocumentStatus.For_Approval]: {
    label: 'For approval',
    color: 'blue',
  },
  [DocumentStatus.For_Periodic_Review]: {
    label: 'For periodic review',
    color: 'blue',
  },
  [DocumentStatus.For_Retirement]: {
    label: 'For retirement',
    color: 'red',
  },
  [DocumentStatus.For_Review]: {
    label: 'For review',
    color: 'blue',
  },
  [DocumentStatus.Retired]: {
    label: 'Retired',
    color: 'red',
  },
  [DocumentStatus.Superseded]: {
    label: 'Superseded',
    color: 'red',
  },
};
