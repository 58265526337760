import {
  Filtering,
  QBox,
  QMultiSelect,
  QSelectItem,
  QSelectPlaceholder,
  QText,
} from '@qualio/ui-components';
import { useMemo } from 'react';
import { QualioDocument } from '../../../../api/model/document';
import { Template } from '../../../../api/templateApi';
import { sortByLabel } from '../../../../util/SelectionOptionUtils';

type TemplatesFilterProps = {
  isLoadingTemplates: boolean;
  templates: Template[];
  setSelectedDocumentsMap?: (selected: Map<number, QualioDocument>) => void;
};

export const TemplatesFilter = ({
  isLoadingTemplates,
  templates,
  setSelectedDocumentsMap,
}: TemplatesFilterProps) => {
  const filtering = Filtering.useFiltering();
  const templateParams = filtering.filters?.filteringTemplates?.value;
  const selectedTemplates = templateParams?.length
    ? templateParams[0].split(',')
    : [];

  const templateOptions: QSelectItem<string, string>[] = useMemo(
    () =>
      templates
        .map((template) => ({
          label: `${template.name} (${template.prefix})`,
          value: template.prefix.toString(),
        }))
        .sort(sortByLabel),
    [templates],
  );

  const onTemplateFilterChange = (
    templateItem: readonly QSelectItem<string, string>[],
  ) => {
    const templateIds = templateItem.map((tagItem) => tagItem.value);
    filtering.setFilters({
      filteringTemplates: templateIds.join(','),
    });
    // reset selection in workspace
    if (setSelectedDocumentsMap) {
      setSelectedDocumentsMap(new Map());
    }
  };

  return (
    <QBox w="300px">
      <QMultiSelect
        isLoading={isLoadingTemplates}
        size="sm"
        options={templateOptions}
        onChange={onTemplateFilterChange}
        value={selectedTemplates}
        aria-label="Document templates"
        data-cy={'templates-filter'}
      >
        <QSelectPlaceholder>
          <QText fontSize="sm">Filter by type...</QText>
        </QSelectPlaceholder>
      </QMultiSelect>
    </QBox>
  );
};
