import {
  CurrentUser,
  QualioQThemeProvider,
  ToastProvider,
} from '@qualio/ui-components';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ErrorHandlerProvider } from '../ErrorHandler';
import { GenerateTrainingAssessmentComponent } from './GenerateTrainingAssessment/GenerateTA';

export const AiPocApp: React.FC<{
  currentUser: CurrentUser;
  containerId: 'generatecc-container' | 'generatetrainingassessment-container';
}> = ({ currentUser, containerId }) => {
  return (
    <div>
      <ErrorHandlerProvider>
        <Router>
          <Routes>
            <Route
              path={'/workspace/documents/:id/edit'}
              element={
                <QualioQThemeProvider>
                  <ToastProvider enableDelegation={true}>
                    {containerId === 'generatetrainingassessment-container' && (
                      <GenerateTrainingAssessmentComponent
                        currentUser={currentUser}
                      ></GenerateTrainingAssessmentComponent>
                    )}
                  </ToastProvider>
                </QualioQThemeProvider>
              }
            ></Route>
          </Routes>
        </Router>
      </ErrorHandlerProvider>
    </div>
  );
};
