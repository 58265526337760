import { useCurrentUser } from '@qualio/ui-components';
import { useQuery } from 'react-query';
import templateApi, { Template } from '../api/templateApi';

type UseTemplatesProps = {
  useMtbeTemplates: boolean;
  includeArchived: boolean;
};
export const useTemplates = ({
  useMtbeTemplates,
  includeArchived,
}: UseTemplatesProps) => {
  const currentUser = useCurrentUser();
  const fetchTemplates = async (): Promise<Template[]> => {
    if (includeArchived) {
      if (useMtbeTemplates) {
        return templateApi.getAllTemplatesV2(currentUser.companyId);
      }
      return templateApi.getAllTemplates(currentUser.companyId);
    }
    const getTemplatesFn = useMtbeTemplates
      ? templateApi.getTemplates
      : templateApi.getTemplatesForCompany;
    return getTemplatesFn(currentUser.companyId);
  };

  return useQuery({
    queryFn: fetchTemplates,
    queryKey: ['templates', currentUser.companyId],
    refetchOnWindowFocus: false,
  });
};
