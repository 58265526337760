import { CKEditorContext } from '@ckeditor/ckeditor5-react';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Context } from '@ckeditor/ckeditor5-core';
import DecoupledEditor from '@ckeditor/ckeditor5-editor-decoupled/src/decouplededitor';
import { useCurrentUser, useToastProvider } from '@qualio/ui-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSearchParams } from 'react-router-dom';
import { getContextConfig } from '../../../../../config/DocumentEditorConfig';
import {
  createContextReadyHandler,
  enableCKEditorMode,
} from '../../../../../config/handlers';
import { useAnnotationHighlighting } from '../../../../../hooks/AnnotationHighlighting';
import { useElementIsRendered } from '../../../../../hooks/ElementRenderedCheck';
import {
  CKE_EDITOR_ERROR,
  logCustomError,
} from '../../../../../messages/LogErrorMessages';
import { CKEditorInstance } from '../../../../../types/CKEditorInstance';
import { CKEditorUtils } from '../../../../../util/CKEditorUtils';
import {
  buildAttachmentsErrorMessage,
  buildToastDescription,
  isAttachmentError,
} from '../../../../../util/ErrorManagementUtils';
import { refreshDisplayMode } from '../../../../../util/SidebarUtils';
import { ToolbarManager } from '../../../../../util/ToolbarManager';
import { saveSectionFromEditor } from '../../../../reducers/DocumentReducer';
import { LoadingSpinner } from '../../../LoadingSpinner/LoadingSpinner';
import {
  DocumentOverviewContext,
  EditorStatus,
  EditorStatusContext,
} from '../../Context';
import { HTMLViewer } from '../../HTMLViewer';
import { SectionedContentEditor } from './DocumentSectionEditor/SectionedContentEditor';

type DocumentSectionEditorProps = {
  handleAttachmentClick: (attachmentId: string) => void;
  handleSessionDisconnectionEvent: (didReceive: boolean) => void;
};

export const DocumentSectionsManager = memo(
  ({
    handleAttachmentClick,
    handleSessionDisconnectionEvent,
  }: DocumentSectionEditorProps) => {
    const {
      qualioDocument,
      currentEditorMode,
      collaborationToken,
      commentsPermission,
    } = useContext(DocumentOverviewContext);
    const { setDocEditorStatus } = useContext(EditorStatusContext);
    const { handleHighlighting } = useAnnotationHighlighting();
    const currentUser = useCurrentUser();
    const { newDocumentLayout } = useFlags();
    const allowScrollOutOfView = newDocumentLayout;

    const [isSideBarRendered, sidebarElement] =
      useElementIsRendered('#commentsSidebar');
    const [, docPresenceListContainer] = useElementIsRendered(
      '.doc-overview-presence-list',
    );

    const [, toolbarWrapperElement] = useElementIsRendered('#toolbarWrapper');
    const [editors, setEditors] = useState<CKEditorInstance[]>([]);
    const { editorBundleEnabled } = useFlags();
    const aiEnabled = currentUser.company.ai_enabled;
    const { showToast } = useToastProvider();
    const [searchParams] = useSearchParams();
    const targetId = searchParams.get('targetId') ?? undefined;
    const contextConfig = useMemo(() => {
      return getContextConfig({
        collaborationToken,
        sidebarConfig: {
          sidebarElement,
          preventScrollOutOfView: !allowScrollOutOfView,
        },
        presenceListConfig: {
          containerElement: docPresenceListContainer,
        },
        editorBundleVersion: editorBundleEnabled
          ? process.env.REACT_APP_EDITOR_BUNDLE_VERSION
          : undefined,
        editorAiEnabled: aiEnabled,
      });
    }, [
      collaborationToken,
      sidebarElement,
      editorBundleEnabled,
      aiEnabled,
      docPresenceListContainer,
      allowScrollOutOfView,
    ]);

    useEffect(() => {
      setDocEditorStatus(EditorStatus.CONNECTING);

      return () => {
        setDocEditorStatus(EditorStatus.STABLE);
      };
    }, [setDocEditorStatus]);

    useEffect(() => {
      const tabPanels = document.getElementById('document-overview-tab-panels');
      const observer = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.contentBoxSize) {
            refreshDisplayMode({
              instances: editors,
              windowWidth: entries[0].borderBoxSize[0].inlineSize ?? undefined,
              breakpointOverride: newDocumentLayout ? 1500 : 1000,
            });
          }
        }
      });
      if (tabPanels) {
        observer.observe(tabPanels);
      }
    }, [editors, newDocumentLayout]);

    useEffect(() => {
      if (editors.length > 0) {
        if (commentsPermission <= 1) {
          return;
        }
        enableCKEditorMode(
          currentEditorMode,
          editors,
          new ToolbarManager(toolbarWrapperElement),
          () => {
            //still do nothing?
          },
        );
      }
    }, [currentEditorMode, editors, toolbarWrapperElement, commentsPermission]);

    const onContextReady = useCallback(
      (context: Context) => {
        createContextReadyHandler(
          currentUser,
          (editors: CKEditorInstance[]) => {
            setEditors(editors);
          },
          commentsPermission,
          new ToolbarManager(toolbarWrapperElement),
          'documentCommentControl',
          handleAttachmentClick,
          'documentEditorContent',
          setDocEditorStatus,
          handleHighlighting,
          handleSessionDisconnectionEvent,
          targetId,
        )(context);
      },
      [
        currentUser,
        commentsPermission,
        toolbarWrapperElement,
        handleAttachmentClick,
        handleHighlighting,
        handleSessionDisconnectionEvent,
        setDocEditorStatus,
        targetId,
      ],
    );

    const onContextError = useCallback(() => {
      return (e: any) => {
        logCustomError(CKE_EDITOR_ERROR, { error: e.toString() });
      };
    }, []);

    // https://github.com/ckeditor/ckeditor5/issues/13098
    const watchdogConfig = useMemo(() => {
      return {
        saveInterval: 2147483647,
      };
    }, []);

    const handleAutoSave = useCallback(
      async (editor: CKEditorInstance): Promise<void> => {
        if (commentsPermission <= 1) {
          return;
        }
        await saveSectionFromEditor(
          qualioDocument,
          editor,
          (e) => {
            setDocEditorStatus(EditorStatus.ERROR);
            const errorMsg = isAttachmentError(e)
              ? buildAttachmentsErrorMessage(e, editor, qualioDocument)
              : e;
            showToast({
              id: 'document-editor-save-error',
              status: 'error',
              title: 'Error saving section content',
              description: buildToastDescription(errorMsg),
              replace: true,
            });
            return Promise.resolve();
          },
          () => {
            //no op
          },
        );
      },
      // Do not add showToast to this array, it will crash the editor mid render
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [qualioDocument, setDocEditorStatus, commentsPermission],
    );

    if (!isSideBarRendered || !toolbarWrapperElement) {
      return <LoadingSpinner></LoadingSpinner>;
    }

    const isEditingSessionLoading = editors.length === 0;

    return (
      <>
        {isEditingSessionLoading && (
          <HTMLViewer
            sections={qualioDocument.sections}
            showSectionTitles={qualioDocument.text_sections_titles_visible}
            disableCheckBoxes={true}
          />
        )}
        <div className={`${isEditingSessionLoading ? 'ck-hidden' : ''}`}>
          <CKEditorContext
            context={DecoupledEditor.Context}
            contextWatchdog={DecoupledEditor.ContextWatchdog}
            config={contextConfig}
            watchdogConfig={watchdogConfig}
            onError={onContextError}
            onChangeInitializedEditors={(editors: any) => {
              CKEditorUtils.waitForAllEditorsToStartAndFireReadyHandler(
                editors,
                qualioDocument.sections.length,
                onContextReady,
              );
            }}
          >
            {qualioDocument.sections.map((section) => (
              <SectionedContentEditor
                key={`sectionEditor${section.position}`}
                section={section}
                handleAutoSave={handleAutoSave}
                showSectionTitles={qualioDocument.text_sections_titles_visible}
                entityType={'document.section'}
                collaborationEntityId={qualioDocument.id.toString()}
                collaborationEntityName={'document'}
              />
            ))}
          </CKEditorContext>
        </div>
      </>
    );
  },
);
