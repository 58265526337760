import { CurrentUser } from '@qualio/ui-components';
import { backendTaskApi } from '../../../api/backendTask';
import { documentApi } from '../../../api/document';
import { DocumentStatus, QualioDocument } from '../../../api/model/document';

export enum WorkspaceTabs {
  YOUR_DOCUMENTS = 'your_documents',
  YOUR_ACTIONS = 'your_actions',
  ALL_DOCUMENTS = 'all_documents',
}

type BulkActionButtonId =
  | 'SEND_FOR_REVIEW'
  | 'SEND_FOR_APPROVAL'
  | 'DELETE'
  | 'REVERT_TO_DRAFT'
  | 'CREATE_NEW_DRAFT'
  | 'MAKE_EFFECTIVE'
  | 'RETIRE'
  | 'CHANGE_OWNER'
  | 'ASSIGN_TRAINEES'
  | 'MANAGE_TAGS';

export type BulkActionButtonsConfig = {
  [K in BulkActionButtonId]: {
    label: string;
    requiredPermissions: readonly string[];
    isActionAllowed: (
      user: CurrentUser,
      statuses: QualioDocument['status_id'][],
      currentTab: WorkspaceTabs,
      requiredPermissions: readonly string[],
    ) => boolean;
  };
};

export type ExportMethodType =
  | typeof backendTaskApi.startUserDocumentsExportTask
  | typeof backendTaskApi.startAllDocumentsExportTask
  | typeof backendTaskApi.startUserActionableDocumentsExportTask;

export type defaulFetchMethodType =
  | typeof documentApi.fetchAll
  | typeof documentApi.fetchUserDocs
  | typeof documentApi.fetchUserActionableDocs;

export type StatusFilterMapType = {
  [key: string]: {
    label: string;
    statusFilters: (DocumentStatus | string)[] | null;
    isDefault?: boolean;
  };
};

export interface WorkspaceTabProps {
  id: WorkspaceTabs;
  defaultFetchMethod: defaulFetchMethodType;
  statusFiltersMap: StatusFilterMapType;
  defaultExportTaskMethod: ExportMethodType;
}

export const ALL_OPEN_STATUSES = 'all_open';
export const FOR_TRAINING_STATUS = 'for_training';
export const DEFAULT_PAGE_SIZE = 25;
