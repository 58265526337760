import { ToastProvider, useCurrentUser } from '@qualio/ui-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { documentApi } from '../../../api/document';
import { DocumentFormat, QualioDocument } from '../../../api/model/document';
import {
  exportDocumentViaMedtechTask,
  exportViaPdfApi,
  QualioPdfExportCommandOptions,
} from '../../../plugins/PdfExport/QualioPdfExport';
import { convertApiDocumentToViewableInstance } from '../../../util/DocumentApiUtils';
import { DocumentPresentationUtils } from '../../../util/DocumentPresentationUtils';
import { DocumentExportModal } from './DocumentExportModal/index';

type ExportWrapperProps = {
  qualioDocument: QualioDocument;
  isDocumentExportModalOpen: boolean;
  onClose: () => void;
};

export const ExportWrapper = (props: ExportWrapperProps) => {
  const currentUser = useCurrentUser();

  const {
    useLegacyExport,
    auditControlledExports: shouldAuditControlledExports,
    processImagesOnExport,
    santitizeHtmlView,
  } = useFlags();

  const exportQualioDoc = (
    coverPageEnabled: boolean,
    controlledCopy: boolean,
    orientation: 'portrait' | 'landscape' | undefined,
    onDocumentExportCompletion: () => void,
    onDocumentExportFailure: () => void,
    onAttachmentDownloadFailure: () => void,
  ) => {
    documentApi
      .fetch(props.qualioDocument.id.toString(), 1, 1)
      .then((documentFromApi) => {
        const convertedDocument =
          convertApiDocumentToViewableInstance(documentFromApi);

        const documentHtml =
          DocumentPresentationUtils.determineHtmlDocumentContentFromDocument(
            convertedDocument,
            false,
            true,
            useLegacyExport,
            santitizeHtmlView,
          );

        const options: QualioPdfExportCommandOptions = {
          qualioDocument: documentFromApi,
          coverPageEnabled,
          controlledCopy,
          orientation,
          onDocumentExportCompletion,
          onDocumentExportFailure,
          onAttachmentDownloadFailure,
          currentUser,
          documentHtml,
          processImagesOnExport,
          shouldAuditControlledExports,
          useLegacyExport,
        };
        void exportViaPdfApi(options);
      })
      .catch((err) => {
        console.warn(err.toString());
        onDocumentExportFailure();
      });
  };

  const onExportDocument = (
    coverPageEnabled: boolean,
    controlledCopy: boolean,
    orientation: 'portrait' | 'landscape' | undefined,
    onDocumentExportCompletion: () => void,
    onDocumentExportFailure: () => void,
    onAttachmentDownloadFailure: () => void,
  ) => {
    if (
      props.qualioDocument.document_format === DocumentFormat.DocumentUpload ||
      props.qualioDocument.editor_version === null
    ) {
      exportDocumentViaMedtechTask(
        props.qualioDocument,
        onDocumentExportCompletion,
        onDocumentExportFailure,
        coverPageEnabled,
        controlledCopy,
        shouldAuditControlledExports,
      ).catch(() => onDocumentExportFailure());
    } else {
      exportQualioDoc(
        coverPageEnabled,
        controlledCopy,
        orientation,
        onDocumentExportCompletion,
        onDocumentExportFailure,
        onAttachmentDownloadFailure,
      );
    }
  };

  return (
    <ToastProvider enableDelegation={true}>
      <DocumentExportModal
        isOpen={props.isDocumentExportModalOpen}
        onClose={props.onClose}
        document={props.qualioDocument}
        onExportDocument={onExportDocument}
        currentUser={currentUser}
      />
    </ToastProvider>
  );
};
