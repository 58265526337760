import { medtechApiClient, templateApiClient } from './index';
import { MedtechCompanyUserGroup } from './user';

export type Template = {
  id: number;
  template_uuid: string;
  name: string;
  prefix: string;
  company_id: number;
  status: string;
  version: number;
  document_effective_on_approval: boolean;
  training_required: boolean;
  training_available: boolean;
  document_review_period: boolean;
  document_review_due_in_days: number;
  document_approval_due_in_days: number;
  document_text_sections_visible: boolean;
  file_download_user_groups: MedtechCompanyUserGroup[];
  created_time: Date;
  modified_time?: Date;
};

const templateApi = {
  async getTemplatesForCompany(
    companyId: number,
    archived = false,
  ): Promise<Template[]> {
    const res = await templateApiClient.get(
      `/companies/${companyId}/templates`,
      {
        params: {
          archived,
        },
      },
    );
    return res.data.data as Template[];
  },
  async getTemplates(companyId: number): Promise<Template[]> {
    const res = await medtechApiClient.get(
      `/${companyId}/document_templates?statuses=effective`,
    );
    return res.data as Template[];
  },
  async getArchivedTemplates(companyId: number): Promise<Template[]> {
    const res = await medtechApiClient.get(
      `/${companyId}/document_templates?statuses=archived`,
    );
    return res.data as Template[];
  },
  async getAllTemplates(companyId: number): Promise<Template[]> {
    const effectiveTemplatesPromise = await this.getTemplatesForCompany(
      companyId,
      false,
    );
    const archivedTemplatesPromise = await this.getTemplatesForCompany(
      companyId,
      true,
    );
    const [effectiveTemplates, archivedTemplates] = await Promise.all([
      effectiveTemplatesPromise,
      archivedTemplatesPromise,
    ]);
    return [...effectiveTemplates, ...archivedTemplates];
  },
  async getAllTemplatesV2(companyId: number): Promise<Template[]> {
    const effectiveTemplatesPromise = await this.getTemplates(companyId);
    const archivedTemplatesPromise = await this.getArchivedTemplates(companyId);
    const [effectiveTemplates, archivedTemplates] = await Promise.all([
      effectiveTemplatesPromise,
      archivedTemplatesPromise,
    ]);
    return [...effectiveTemplates, ...archivedTemplates];
  },
};

export default templateApi;
