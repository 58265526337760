import { QualioDocument } from '../api/model/document';

export type DocumentEditorWindowData = {
  lastUpdated?: string | number;
  loaded?: boolean;
  resetEditorSession?: () => void;
  triggerExport?: () => void;
  document: QualioDocument;
  containerHtmlElement?: HTMLElement | null;
  activityLogTracking: {
    editedDocumentSections: Set<number>;
    editedChangeControlSections: Set<number>;
  };
};

export const getDocumentEditorGlobalWindowObject =
  (): DocumentEditorWindowData => {
    const w = window as any;
    if (!w.documentEditor) {
      w.documentEditor = {};
    }
    return w.documentEditor as DocumentEditorWindowData;
  };
