import { AIAssistant, AWSTextAdapter } from '@ckeditor/ckeditor5-ai';
import { Alignment } from '@ckeditor/ckeditor5-alignment';
import { Autoformat } from '@ckeditor/ckeditor5-autoformat';
import { Autosave } from '@ckeditor/ckeditor5-autosave';
import {
  Bold,
  Italic,
  Strikethrough,
  Subscript,
  Superscript,
  Underline,
} from '@ckeditor/ckeditor5-basic-styles';
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote';
import { CodeBlock } from '@ckeditor/ckeditor5-code-block';
import { Comments } from '@ckeditor/ckeditor5-comments';
import { Plugin } from '@ckeditor/ckeditor5-core';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import {
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
} from '@ckeditor/ckeditor5-font';
import { Heading } from '@ckeditor/ckeditor5-heading';
import { HorizontalLine } from '@ckeditor/ckeditor5-horizontal-line';
import {
  AutoImage,
  Image,
  ImageCaption,
  ImageInsert,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
} from '@ckeditor/ckeditor5-image';
import { Indent, IndentBlock } from '@ckeditor/ckeditor5-indent';
import { AutoLink, Link, LinkImage } from '@ckeditor/ckeditor5-link';
import { List, ListProperties } from '@ckeditor/ckeditor5-list';
import { MultiLevelList } from '@ckeditor/ckeditor5-list-multi-level';
import { MediaEmbed } from '@ckeditor/ckeditor5-media-embed';
import { Mention } from '@ckeditor/ckeditor5-mention';
import { PageBreak } from '@ckeditor/ckeditor5-page-break';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { PasteFromOffice } from '@ckeditor/ckeditor5-paste-from-office';
import { PasteFromOfficeEnhanced } from '@ckeditor/ckeditor5-paste-from-office-enhanced';
import {
  RealTimeCollaborativeComments,
  RealTimeCollaborativeTrackChanges,
} from '@ckeditor/ckeditor5-real-time-collaboration';
import { RemoveFormat } from '@ckeditor/ckeditor5-remove-format';
import {
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
} from '@ckeditor/ckeditor5-special-characters';
import {
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
} from '@ckeditor/ckeditor5-table';
import { TrackChanges } from '@ckeditor/ckeditor5-track-changes';
import { TextTransformation } from '@ckeditor/ckeditor5-typing';
import { FileRepository } from '@ckeditor/ckeditor5-upload';
import { QualioClipboardPlugin } from '../plugins/Clipboard/QualioClipboardPlugin';
import { QualioCodeBlockPlugin } from '../plugins/CodeBlock/QualioCodeBlockPlugin';

export const EDITOR_AI_PLUGINS: (typeof Plugin)[] = [
  AIAssistant,
  AWSTextAdapter,
];

declare global {
  const CKEditor5: any;
}

export const CONTENT_EDITOR_PLUGINS: (typeof Plugin)[] = [
  Alignment,
  Autoformat,
  AutoImage,
  AutoLink,
  Autosave,
  Bold,
  BlockQuote,
  CodeBlock,
  Comments,
  List,
  ListProperties,
  Essentials,
  FileRepository,
  CKEditor5.fileUpload.QualioFileUpload,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  Heading,
  HorizontalLine,
  Image,
  ImageCaption,
  ImageInsert,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  LinkImage,
  Link,
  MediaEmbed,
  Mention,
  MultiLevelList,
  Paragraph,
  PageBreak,
  PasteFromOfficeEnhanced,
  CKEditor5.checkbox.QualioCheckbox,
  CKEditor5.sectionList.QualioSectionList,
  PasteFromOffice,
  QualioCodeBlockPlugin,
  RealTimeCollaborativeComments,
  RealTimeCollaborativeTrackChanges,
  RemoveFormat,
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  Strikethrough,
  Subscript,
  Superscript,
  Table,
  TableCaption,
  TableCellProperties,
  TableProperties,
  TableToolbar,
  TextTransformation,
  TrackChanges,
  Underline,
  QualioClipboardPlugin,
  TableColumnResize,
  CKEditor5.smartlink.QualioSmartlink,
];

export const getContentEditorPlugins = () => {
  return CONTENT_EDITOR_PLUGINS;
};

export const getMentionPluginConfig = (companyId: number) => {
  return CKEditor5.smartlink.QualioSmartlink.getMentionConfig(
    companyId,
    process.env.REACT_APP_GLOBAL_SEARCH_SERVICE_URL,
  );
};
