import { Marker } from '@ckeditor/ckeditor5-engine';

export function formTargetsFromMarker(
  marker: Marker,
): Array<CommentsRepository.Target> {
  const items = [];
  for (const coveredItem of marker.getRange().getItems()) {
    if (coveredItem.is('$textProxy')) {
      items.push({ name: 'text', data: coveredItem.data });
    } else if (coveredItem.is('element')) {
      const name = coveredItem.name.toString();
      if (['imageInline', 'imageBlock'].includes(name)) {
        items.push({
          name: name,
          data: coveredItem.getAttribute('src')?.toString() ?? '',
        });
      } else if (
        ['table', 'codeBlock', 'checkbox', 'blockQuote'].includes(name)
      ) {
        items.push({ name: name, data: '' });
      }
    }
  }
  return items;
}
