import { QText } from '@qualio/ui-components';
import { DocumentStatus } from '../../../../api/model/document';
import { DocumentStatusIdToStatusConfigMap } from '../../../../util/WorkspaceUtils';
import { ALL_OPEN_STATUSES, FOR_TRAINING_STATUS } from '../types';

export const ActiveStatusFilter = ({ value }: { value: unknown }) => {
  // Attempt to render the status label
  if (Object.values(DocumentStatus).includes(value as DocumentStatus)) {
    const label =
      DocumentStatusIdToStatusConfigMap[value as DocumentStatus]?.label;
    return <QText>{label}</QText>;
  }

  // If status is "ALL_OPEN_STATUSES" or "FOR_TRAINING_STATUS", then render a custom label
  if (value === ALL_OPEN_STATUSES) {
    return <QText>All open statuses</QText>;
  }

  if (value === FOR_TRAINING_STATUS) {
    return <QText>For training</QText>;
  }

  // If the value is not a valid status, render the value as a string
  return <QText>{value}</QText>;
};
