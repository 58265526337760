import {
  QButton,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QText,
} from '@qualio/ui-components';

export interface DeleteEntityModalProps {
  isOpen: boolean;
  isDeletingEntity: boolean;
  onCloseModal: () => void;
  deleteHandler: () => void;
  isDestructive?: boolean;
  modalText?: JSX.Element;
  entityName?: string;
}

const DEFAULT_ENTITY_NAME = 'file';

const DEFAULT_MODAL_TEXT = (
  <p>
    Are you certain you want to permanently remove the selected{' '}
    {DEFAULT_ENTITY_NAME}? Once deleted, it cannot be recovered.
  </p>
);

export const DeleteEntityModal = ({
  isOpen,
  isDeletingEntity,
  onCloseModal,
  deleteHandler,
  isDestructive = true,
  modalText = DEFAULT_MODAL_TEXT,
  entityName = DEFAULT_ENTITY_NAME,
}: DeleteEntityModalProps) => {
  return (
    <QModal isOpen={isOpen} onClose={onCloseModal}>
      <QModalHeader>
        <QText>Delete {entityName}</QText>
      </QModalHeader>
      <QModalBody>{modalText}</QModalBody>
      <QModalActions>
        <QButton variant="outline" onClick={onCloseModal}>
          Cancel
        </QButton>
        {isDestructive ? (
          <QButton
            data-cy={'confirm-delete-button'}
            isDestructive
            isLoading={isDeletingEntity}
            onClick={deleteHandler}
          >
            Delete
          </QButton>
        ) : (
          <QButton
            data-cy={'confirm-delete-button'}
            isLoading={isDeletingEntity}
            onClick={deleteHandler}
          >
            Delete
          </QButton>
        )}
      </QModalActions>
    </QModal>
  );
};
